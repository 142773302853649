import {Injectable} from "@angular/core";
import {BehaviorSubject, Subject} from "rxjs";
import {NavbarComponent} from "./navbar.component";

@Injectable({
  providedIn: 'root'
})
export class NavbarService {

  public targetComponent: NavbarComponent = null;

  public registerTargetComponent(targetComponent) {
    this.targetComponent = targetComponent;
  }

  public setDarkMode(darkMode: boolean) {
    if(darkMode != null) {
      this.targetComponent.darkMode = darkMode;
      this.reloadMainMenu();
      this.targetComponent.showNavbar = true;
    }
  }

  public setLogoDark(logoDark: boolean) {
    if(logoDark != null) {
      this.targetComponent.logoDark = logoDark;
      this.reloadMainMenu();
      this.targetComponent.showNavbar = true;
    }
  }

  public setShowSections(showSections: boolean) {
    if(showSections != null) {
      this.targetComponent.showSections = showSections;
      this.reloadMainMenu();
      this.targetComponent.showNavbar = true;
    }
  }

  public setShowNavbar(showNavBar: boolean) {
    if(showNavBar != null) {
      if(showNavBar) {
        this.reloadMainMenu();
      }
      this.targetComponent.showNavbar = showNavBar;
    }
  }

  public reloadMainMenu() {
    if(!this.targetComponent.showNavbar) {
      setTimeout(() => {
        this.targetComponent.loadMainMenu();
      }, 100);
    }
  }

}
